




























































































import ModalAddPeakSupport from "@/components/modal/user/ModalAddPeakSupport.vue";
import { LoadingStatusType, Role, TableSorting, UserOrderBy } from "@/enums/enums";
import { NewObject } from "@/helper/shareHelper";
import { UserTable, UserTableDetail, UserTableRequest } from "@/model/user/userDetailModel";
import AuthService from "@/service/authService";
import ChangeSuperuserActionList from "@/views/merchant/ChangeSuperuserActionList.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const StoreMerchant = namespace("Merchant");
const StoreActivityLog = namespace("ActivityLog");
const StoreChangeSuperuser = namespace("ChangeSuperuser");

const auth = new AuthService();
@Component({ components: { ChangeSuperuserActionList, ModalAddPeakSupport } })
export default class ChangeSuperuser extends Vue {
  private textSearch = "";
  isShowAddPeakSupportModal = false;
  private table: any = {
    header: [
      { text: "", value: "index" },
      { text: "Id", value: "Id" },
      { text: "Username", value: "Username" },
      { text: "DisplayName", value: "DisplayName" },
      { text: "Role", value: "Role" },
      { text: "Superadmins", value: "Superadmin" },
      { text: "Action", value: "Action" },
    ],
    detail: [],
  };
  constructor() {
    super();
    this.getUserPermissions();
  }
  private enabled = null;
  private dateCurrent = new Date();
  private thirdPreviosDate = this.dateCurrent.setDate(this.dateCurrent.getDate() - 3);
  private datefrom = new Date(this.thirdPreviosDate - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);
  private menufrom = false;
  private modalfrom = false;
  private menu2from = false;
  private dateto = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
  private menuto = false;
  private modalto = false;
  private menu2to = false;
  private pageNo = 1;
  private pageSize = 10;
  private actionValue = 0;

  private countItem = 0;
  private totalPage = 0;
  private pageNoList: Array<number> = [];
  private pageSizeList = [5, 10, 25, 50, 100];
  private merchantId = 0;
  permission = false;
  isHaveSupportUser = false;

  @StoreChangeSuperuser.Getter
  private getCountItemTable!: number;

  @StoreChangeSuperuser.Getter
  private getUserTableLoadingStatus!: LoadingStatusType;

  @StoreChangeSuperuser.Getter
  private getAddPeakSupportLoadingStatus!: LoadingStatusType;

  @StoreChangeSuperuser.Getter
  private getDeletePeakSupportLoadingStatus!: LoadingStatusType;

  @StoreChangeSuperuser.Action
  private DoGetUserTable!: (userTable: UserTableRequest) => void;
  @StoreChangeSuperuser.Getter
  private getUserDetailListModel!: UserTable | null;

  @StoreChangeSuperuser.Getter
  private getUpdateSuperUserLoadingStatus!: LoadingStatusType;

  @Watch("pageNo")
  pageNoChanged(newVal: number) {
    this.GetUserTable(this.pageNo, this.pageSize);
  }

  @Watch("pageSize")
  pageSizeChanged(newVal: number) {
    this.GetUserTable(1, this.pageSize);
  }

  @Watch("actionValue")
  actionDropdownChanged(newVal: number) {
    this.actionValue = newVal;
  }
  @Watch("getUpdateSuperUserLoadingStatus")
  getupdateSuperUserLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Success) {
      this.GetUserTable();
    }
  }
  @Watch("getAddPeakSupportLoadingStatus")
  getAddPeakSupportLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Success) {
      this.GetUserTable();
    }
  }
  @Watch("getDeletePeakSupportLoadingStatus")
  getDeletePeakSupportLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Success) {
      this.GetUserTable();
    }
  }

  @Watch("getUserTableLoadingStatus")
  getuserTableLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Success) {
      this.table.detail = [];
      if (this.getUserDetailListModel && this.getUserDetailListModel.userTableDetails.length > 0) {
        this.isHaveSupportUser = this.getUserDetailListModel.isHaveSupportUser;
        this.getUserDetailListModel.userTableDetails.forEach((item: UserTableDetail) => {
          this.table.detail.push(NewObject(item));
        });
      }
      this.countItem = this.getCountItemTable;

      if (this.countItem === 0) {
        this.totalPage = 1;
      } else {
        this.totalPage = Math.ceil(this.getCountItemTable / this.pageSize);
      }
      this.pageNoList = [];
      for (let index = 0; index < this.totalPage; index++) {
        this.pageNoList.push(index + 1);
      }
    }
  }

  created() {
    const emtStr: any = this.$route.query.emi;
    const name: any = this.$route.query.name;
    if (emtStr && emtStr.PeakDecryption() > 0) {
      this.merchantId = emtStr.PeakDecryption();
      this.GetUserTable(this.pageNo, this.pageSize);
    }
  }

  public GetUserTable(pageNo = this.pageNo, pageSize = this.pageSize) {
    const userTableModel: UserTableRequest = {
      pageNo: pageNo,
      pageSize: pageSize,
      tableSorting: TableSorting.DESC,
      merchantId: this.merchantId,
      searchText: "",
      userOrderBy: UserOrderBy.DisplayName,
    };
    this.pageNo = pageNo;
    this.pageSize = pageSize;
    this.DoGetUserTable(userTableModel);
  }

  public SearchActivityLog() {
    this.GetUserTable(this.pageNo, this.pageSize);
  }

  public LabelNumber(index: number) {
    if (this.pageNo > 1) {
      return index + 1 + this.pageSize * (this.pageNo - 1);
    } else {
      return index + 1;
    }
  }
  public SuperUser(isSuperUser: boolean) {
    let icon = "";
    if (isSuperUser) {
      icon = "mdi-check";
    } else {
      icon = "mdi-close";
    }
    return icon;
  }
  public Role(role: number) {
    let roleName = "";
    switch (role) {
      case Role.ViewOnly:
        roleName = "ดูได้อย่างเดียว";
        break;
      case Role.PartialRightsDraftOnly:
        roleName = "สิทธิบางส่วน ร่างได้อย่างเดียว";
        break;
      case Role.PartialRightsApproveAndReceivePayment:
        roleName = "สิทธิบางส่วน อนุมัติและรับจ่าย";
        break;
      case Role.PartialRightsRevenueOnly:
        roleName = "สิทธิบางส่วน ฝั่งรายได้อย่างเดียว";
        break;
      case Role.PartialRightsExpenseOnly:
        roleName = "สิทธิบางส่วน ฝั่งรายจ่ายอย่างเดีย";
        break;
      case Role.PartialRightsQuotationOnly:
        roleName = "สิทธิบางส่วน ใบเสนอราคาอย่างเดียว";
        break;
      case Role.StandardRightsViewReport:
        roleName = "สิทธิมาตรฐานแบบดูรีพอรต์ได้";
        break;
      case Role.StandardRightsNotViewReport:
        roleName = "สิทธิมาตรฐานแบบดูรีพอร์ตไม่ได้";
        break;
      case Role.Admin:
        roleName = "ผู้ดูแลระบบ";
        break;
      case Role.PartialRightsCreateRevenueOnly:
        roleName = "สิทธิบางส่วน สร้างฝั่งรายได้อย่างเดียว";
        break;
      case Role.PartialRightsCreateExpenseOnly:
        roleName = "สิทธิบางส่วน สร้างฝั่งรายจ่ายอย่างเดียว";
        break;
    }
    return roleName;
  }
  public async getUserPermissions() {
    const accessPermission = await auth.getPermissions();
    this.permission = accessPermission["merchant"]["addSupport"];
  }
}
