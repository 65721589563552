



















import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { LoadingStatusType } from "@/enums/enums";
import { NewObject } from "@/helper/shareHelper";
import { MerchantDropdownModel } from "@/model/merchant/merchantDropdownModel";

const StoreMerchant = namespace("Merchant");
const StoreChangeSuperuser = namespace("ChangeSuperuser");

@Component
export default class ModalConfirmChangeSuperuser extends Vue {
  @Prop({ required: true }) readonly item!: any;
  @Prop({ required: true }) readonly superuser!: any;
  @Prop({ required: true }) readonly asana!: string;
  @Prop({ required: true }) readonly remark!: string;
  @Prop({ required: true }) readonly merchantId!: number;
  private isShowConfirmChangeSuperuser = true;

  private isAsana = true;
  private searchTerm = "";

  @StoreChangeSuperuser.Action
  private DoUpdateSuperUser!: (superUserId: number) => void;

  @Watch("isShowConfirmChangeSuperuser")
  isShowConfirmChangeSuperuserChanged(newVal: boolean) {
    if (!this.isShowConfirmChangeSuperuser) {
      this.$emit("update:isShowConfirmChangeSuperuser", false);
    }
  }
  public GetAsana(asana: any) {
    this.ValidateAsana();
  }

  public ValidateAsana() {
    if (this.asana !== null && this.asana !== "") {
      this.isAsana = true;
    } else {
      this.isAsana = false;
    }
    return this.isAsana;
  }
  public SuperUserDetail() {
    let superUserDetail = this.superuser.find((x: { isSuperUser: boolean }) => x.isSuperUser == true);
    return superUserDetail;
  }
  public DoConfirmUpdateSuperUser(
    merchantId = this.merchantId,
    oldSuperUser: any = {
      id: this.superuser.userProfileMerchantId,
      userId: this.superuser.id,
      isSuperUser: this.superuser.isSuperUser ? 0 : 1,
      userName: this.superuser.userName,
    },
    newSuperUser: any = {
      id: this.item.userProfileMerchantId,
      userId: this.item.id,
      isSuperUser: this.item.isSuperUser ? 0 : 1,
      userName: this.item.userName,
    },
    merchantActionOption: any = {
      asanaNumber: this.asana,
      remark: this.remark,
    }
  ) {
    const inputRequest: any = {
      oldSuperUser: oldSuperUser,
      newSuperUser: newSuperUser,
      merchantActionOption: merchantActionOption,
      merchantId: merchantId,
    };
    this.DoUpdateSuperUser(inputRequest);
    this.$emit("DoConfirmUpdateSuperUser");
    this.isShowConfirmChangeSuperuser = false;
  }
}
