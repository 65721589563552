






import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ChangeSuperuserTable from "./ChangeSuperuserTable.vue";

@Component({ components: { ChangeSuperuserTable } })
export default class ChangeSuperuser extends Vue {}
