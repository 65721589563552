































































import ModalCancelPeakPayment from "@/components/modal/merchant/ModalCancelPeakPayment.vue";
import ModalConfirmSwapMerchant from "@/components/modal/merchant/ModalConfirmSwapMerchant.vue";
import ModalSwapMerchant from "@/components/modal/merchant/ModalSwapMerchant.vue";
import ModalChangeSuperuser from "@/components/modal/user/ModalChangeSuperuser.vue";
import ModalDeletePeakSupport from "@/components/modal/user/ModalDeletePeakSupport.vue";
import { ChangeSuperUserAction } from "@/enums/enums";
import { MerchantDetailModel } from "@/model/merchant/merchantDetailModel";
import AuthService from "@/service/authService";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const auth = new AuthService();
const StoreMerchant = namespace("Merchant");

@Component({
  components: {
    ModalSwapMerchant,
    ModalCancelPeakPayment,
    ModalConfirmSwapMerchant,
    ModalChangeSuperuser,
    ModalDeletePeakSupport,
  },
})
export default class MerchantActionList extends Vue {
  @Prop({ required: true }) readonly item!: any;
  @Prop({ required: true }) readonly superuser!: any;
  @Prop({ required: true }) readonly merchantId!: any;
  @Prop({ required: true }) readonly isHaveSupportUser!: any;

  private isShowConfirmSwapMerchantModal = false;
  private isShowChangeSuperuser = false;
  private isShowDeletePeakSupportModal = false;
  private textConfirmSwapMerchant = "";
  private merchantDropdownModelSelect: any = null;
  private remark = "";
  private asana = "";

  @StoreMerchant.Getter
  private getMerchantDetailListModel!: any;

  @StoreMerchant.Getter
  private getMerchantDropdownByMerchantId!: any;

  @StoreMerchant.Action
  private DoSetMerchantDetailSelect!: (merchantDetailModel: MerchantDetailModel) => void;

  private showList = false;
  private isShowSwapMerchantModal = false;
  private isShowCancelPaymentPackageModal = false;
  permissionSuperUser = false;
  permissionRemoveSupport = false;
  menuPermission = false;

  constructor() {
    super();
    this.initializeMenuList();
  }

  private merchantAction: any = [];
  public async initializeMenuList(): Promise<void> {
    const permissions = await this.getPermissions();
    this.menuPermission = permissions.merchant.superUser || permissions.merchant.removeSupport;
    this.merchantAction = [
      {
        text: "เปลี่ยน admin",
        icon: "mdi-account-convert",
        actionValue: ChangeSuperUserAction.ChangeAdmin,
        permission: permissions.merchant.superUser,
      },
      {
        text: "ลบ PEAK Support",
        icon: "mdi-trash-can",
        actionValue: ChangeSuperUserAction.DeleteSupport,
        permission: permissions.merchant.removeSupport,
      },
    ];
  }

  @Watch("isShowConfirmSwapMerchantModal")
  isShowConfirmSwapMerchantModalChanged(newVal: boolean) {
    if (!this.isShowConfirmSwapMerchantModal) {
      this.textConfirmSwapMerchant = "";
    }
  }

  public ShowActionList(newValue: boolean) {
    this.showList = newValue;
  }
  public handleItemClick(actionItem: any) {
    const { actionValue } = actionItem;
    switch (actionValue) {
      case ChangeSuperUserAction.ChangeAdmin:
        this.ChangeSuperuser();
        break;
      case ChangeSuperUserAction.DeleteSupport:
        this.DeletePeakSupport();
        break;
    }
  }
  public handleShowItem(actionItem: any) {
    const { actionValue } = actionItem;
    const { isSupport } = this.item;
    switch (actionValue) {
      case ChangeSuperUserAction.DeleteSupport:
        return isSupport;
      default:
        return true;
    }
  }
  public ChangeSuperuser() {
    this.isShowChangeSuperuser = true;
  }
  public DeletePeakSupport() {
    this.isShowDeletePeakSupportModal = true;
  }

  public CancelPaymentPackage() {
    this.isShowCancelPaymentPackageModal = true;
  }
  public SuperUserDetail() {
    let superUserDetail = this.superuser.find((x: { isSuperUser: boolean }) => x.isSuperUser == true);
    return superUserDetail;
  }

  public async getPermissions() {
    const accessPermission = await auth.getPermissions();
    return accessPermission;
  }
}
