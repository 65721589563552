






























import { LoadingStatusType } from "@/enums/enums";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const StoreMerchant = namespace("Merchant");
const StoreLoading = namespace("Loading");

@Component
export default class ModalConfirmSwapMerchant extends Vue {
  @Prop({ required: true }) readonly merchantDropdownModelSelect!: any;
  @Prop({ required: true }) readonly item!: any;
  @Prop({ required: true }) readonly asana!: string;
  @Prop({ required: true }) readonly remark!: string;

  private isShowConfirmSwapMerchantModal = true;
  private textConfirmSwapMerchant = "";

  @StoreMerchant.Getter
  private getSwapMerchantPackageLoadingStatus!: LoadingStatusType;

  @StoreMerchant.Action
  private DoSwapMerchantPackage!: (inputRequest: any) => void;

  @StoreLoading.Action
  private IsLoading!: (isLoading: boolean) => void;

  created() {
    if (this.merchantDropdownModelSelect) {
      const {
        merchantId: selectedMerchantId,
        merchantName: selectedMerchantName,
        currentMainPackage: selectedPackage,
        expiredDateTime: selectedExpiredDateTime,
      } = this.merchantDropdownModelSelect;

      const { merchantId, merchantName, currentMainPackageName, expiredDateTime } = this.item;

      this.textConfirmSwapMerchant = `
    คุณต้องการสลับกิจการ ${merchantId} - ${merchantName}
    Package: ${currentMainPackageName}
    Expired: ${expiredDateTime}
    กับกิจการ ${selectedMerchantId} - ${selectedMerchantName}
    Package: ${selectedPackage}
    ${selectedExpiredDateTime ? `Expired: ${selectedExpiredDateTime}` : ""}
  `;
    }
  }

  @Watch("getSwapMerchantPackageLoadingStatus")
  getSwapMerchantPackageLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Loading) {
      this.IsLoading(true);
    } else {
      this.IsLoading(false);
      this.CancelModalConfirmSwapMerchant();
    }
  }

  public CancelModalConfirmSwapMerchant() {
    this.$emit("update:isShowConfirmSwapMerchantModal", false);
    this.$emit("update:item", null);
    this.$emit("update:asana", this.asana);
    this.$emit("update:remark", this.remark);
  }

  public DoConfirmSwapMerchantPackage(
    merchantDetailModelSelect = this.item,
    merchantDropdownModelSelect = this.merchantDropdownModelSelect,
    asana = this.asana,
    remark = this.remark
  ) {
    const inputRequest = {
      merchantDetailModel: merchantDetailModelSelect,
      merchantDropdownModel: merchantDropdownModelSelect,
      asana: asana,
      remark: remark,
    };
    this.DoSwapMerchantPackage(inputRequest);
  }
}
