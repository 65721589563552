


































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { LoadingStatusType } from "@/enums/enums";
import { NewObject } from "@/helper/shareHelper";
import { MerchantDropdownModel } from "@/model/merchant/merchantDropdownModel";
import ModalConfirmChangeSuperuser from "@/components/modal/user/ModalConfirmChangeSuperuser.vue";

const StoreMerchant = namespace("Merchant");

@Component({ components: { ModalConfirmChangeSuperuser } })
export default class ModalChangeSuperuser extends Vue {
  @Prop({ required: true }) readonly item!: any;
  @Prop({ required: true }) readonly superuser!: any;
  @Prop({ required: true }) readonly merchantId!: number;
  private isShowChangeSuperuser = true;

  private isAsana = true;
  private asana = "";
  private remark = "";
  private searchTerm = "";
  private isShowConfirmChangeSuperuser = false;

  @Watch("isShowChangeSuperuser")
  isShowSwapMerchantModalChanged(newVal: boolean) {
    if (!this.isShowChangeSuperuser) {
      this.$emit("update:isShowChangeSuperuser", false);
    }
  }

  public GetAsana(asana: any) {
    this.ValidateAsana();
  }

  public ShowModalDetailConfirmChangeSuperuser() {
    if (this.ValidateAsana()) {
      this.$emit("update:isShowConfirmSwapMerchantModal", true);
      this.$emit("update:isShowChangeSuperuser", false);
      this.$emit("update:asana", this.asana);
      this.$emit("update:remark", this.remark);
    }
  }

  public ValidateAsana() {
    if (this.asana !== null && this.asana !== "") {
      this.isAsana = true;
    } else {
      this.isAsana = false;
    }
    return this.isAsana;
  }
  public SuperUserDetail() {
    let superUserDetail = this.superuser.find((x: { isSuperUser: boolean }) => x.isSuperUser == true);
    return superUserDetail;
  }
  public ConfirmChangeSuperuse() {
    this.isShowConfirmChangeSuperuser = true;
  }
  public DoConfirmUpdateSuperUser() {
    this.$emit("DoConfirmUpdateSuperUser");
    this.isShowChangeSuperuser = false;
  }
}
