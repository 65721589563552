


























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { DeletePeakSupportRequest } from "../../../model/user/userDetailModel";

const StoreChangeSuperuser = namespace("ChangeSuperuser");
@Component
export default class ModalDeletePeakSupport extends Vue {
  @Prop({ required: true }) readonly item!: any;
  @Prop({ required: true }) readonly superuser!: any;
  @Prop({ required: true }) readonly merchantId!: number;
  isShowDeletePeakSupportModal = true;
  merchantName = localStorage.getItem("mechantName") || "";

  @StoreChangeSuperuser.Action
  private DoDeletePeakSupport!: (input: DeletePeakSupportRequest) => void;

  @Watch("isShowDeletePeakSupportModal")
  isShowTransferPackageModalChanged(newVal: boolean) {
    if (!this.isShowDeletePeakSupportModal) {
      this.$emit("update:isShowDeletePeakSupportModal", false);
    }
  }

  HideModal() {
    this.isShowDeletePeakSupportModal = false;
  }
  public DeletePeakSupport() {
    const input = {
      merchantId: this.merchantId,
    };
    this.DoDeletePeakSupport(input);
    this.HideModal();
  }
}
