












































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { AddPeakSupportRequest } from "../../../model/user/userDetailModel";

const StoreChangeSuperuser = namespace("ChangeSuperuser");
@Component
export default class ModalAddPeakSupport extends Vue {
  @Prop({ default: 0 }) merchantId!: number;
  isAsana = true;
  isRemark = false;
  asana = "";
  remark = "";

  isShowAddPeakSupportModal = true;

  @StoreChangeSuperuser.Action
  private DoAddPeakSupport!: (input: AddPeakSupportRequest) => void;

  @Watch("isShowAddPeakSupportModal")
  isShowTransferPackageModalChanged(newVal: boolean) {
    if (!this.isShowAddPeakSupportModal) {
      this.$emit("update:isShowAddPeakSupportModal", false);
    }
  }

  HideModal() {
    this.isShowAddPeakSupportModal = false;
  }
  public GetAsana(asana: any) {
    this.ValidateAsana();
  }
  public ValidateAsana() {
    if (this.asana !== null && this.asana !== "" && this.asana.length <= 20) {
      this.isAsana = true;
    } else {
      this.isAsana = false;
    }
    return this.isAsana;
  }
  public ValidateRemark() {
    return this.remark.length >= 0 && this.remark.length <= 300;
  }
  AddPeakSupport() {
    if (this.ValidateAsana() && this.ValidateRemark()) {
      const input: AddPeakSupportRequest = {
        merchantId: this.merchantId,
        asana: this.asana,
        remark: this.remark,
      };
      this.DoAddPeakSupport(input);
      this.HideModal();
    }
  }
}
