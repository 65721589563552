
















































import { LoadingStatusType } from "@/enums/enums";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const StoreMerchant = namespace("Merchant");
const StoreLoading = namespace("Loading");

@Component
export default class ModalCancelPeakPayment extends Vue {
  @Prop({ required: true }) readonly item!: any;

  private isShowCancelPaymentPackageModal = true;
  private errorMessages = "";
  private formHasErrors = false;
  private isAsana = true;
  private asana = "";
  private remark = "";

  @StoreMerchant.Getter
  private getCancelPeakPackageLoadingStatus!: LoadingStatusType;

  @StoreMerchant.Action
  private DoCancelPeakPackage!: (input: any) => void;

  @StoreLoading.Action
  private IsLoading!: (isLoading: boolean) => void;

  @Watch("isShowCancelPaymentPackageModal")
  isShowCancelPaymentPackageModalChanged(newVal: boolean) {
    if (!this.isShowCancelPaymentPackageModal) {
      this.$emit("update:isShowCancelPaymentPackageModal", true);
      this.$emit("update:isShowSwapMerchantModal", false);
    }
  }

  @Watch("getCancelPeakPackageLoadingStatus")
  getCancelPeakPackageLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Loading) {
      this.IsLoading(true);
    } else {
      this.IsLoading(false);
      this.CloseCancelPeakPackageModal();
    }
  }
  public GetAsana(asana: any) {
    this.ValidateAsana();
  }

  public DoConfirmCancelPeakPackage() {
    if (this.ValidateAsana()) {
      const input = {
        asana: this.asana,
        remark: this.remark,
        merchantId: this.item.merchantId,
        peakPaymentConnectV2Id: this.item.peakPaymentConnectV2Id,
      };
      this.DoCancelPeakPackage(input);
    }
  }
  public CloseCancelPeakPackageModal() {
    this.isShowCancelPaymentPackageModal = false;
  }
  public ValidateAsana() {
    if (this.asana !== null && this.asana !== "") {
      this.isAsana = true;
    } else {
      this.isAsana = false;
    }
    return this.isAsana;
  }
}
